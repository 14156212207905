<template>
 <div class="account-table">
    <el-table border :data="list" style="width: 100%" :row-style="{ height: 0 }">
      <el-table-column prop="tempId" label="ID" align="center"></el-table-column>
      <el-table-column prop="productName" label="产品名称" align="center"></el-table-column>
      <el-table-column prop="stagingCount" label="分期数" align="center"></el-table-column>
      <el-table-column prop="serviceRate" label="服务费（%）" align="center"></el-table-column>
      <el-table-column prop="interestRate" label="总利率" align="center"></el-table-column>
      <el-table-column prop="overdueDayRate" label="滞纳金率 (逾期日利率)" align="center"></el-table-column>
      <el-table-column prop="renewDays" label="续期天数" align="center">
      </el-table-column>
<!--      <el-table-column prop="renewDayRate" label="续期日利率" align="center"></el-table-column>-->
      <el-table-column prop="amountMax" label="最大申请金额" align="center">
        <template slot-scope="scope">
          {{scope.row.amountMax | toMoney }}
        </template>
      </el-table-column>
      <el-table-column prop="amountMin" label="最小申请金额" align="center">
        <template slot-scope="scope">
          {{scope.row.amountMin | toMoney }}
        </template>
      </el-table-column>
      <el-table-column prop="interestBeforeLoan" label="放款前扣除利息" align="center">
        <template slot-scope="scope">
          {{scope.row.interestBeforeLoan | isInterestLoan }}
        </template>
      </el-table-column>
      <el-table-column prop="updateAt" label="更新时间" align="center"></el-table-column>
      <el-table-column label="操作" align="center" min-width="100">
        <template slot-scope="scope">
          <!-- 查看 -->
          <!-- <el-button
           type="danger"
            size="mini"
            icon="el-icon-search"
            class="view-btn"
            circle
            @click="$emit('view', scope.row)"
          ></el-button> -->
         <el-button
            type="success"
            size="mini"
            icon="el-icon-edit"
            circle
            @click="$emit('show-edit', scope.row )"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>

  </div>
</template>

<script>
import { base, baseUrl } from "@/api/env";
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data(){
    return{
      base:''
    }
  },
  computed: {
    copyList() {
      let copy = JSON.parse(JSON.stringify(this.list));
      copy.forEach(item => {
        item.showPsd = false;
      });
      return copy;
    }
  },
  methods: {
    checkLock(index, row) {
      row.showPsd = !row.showPsd;
      this.copyList.splice(index, 1, row);
    }
  },
  filters: {
    toMoney(m) {
      return (m / 100.).toFixed(2);
    },
    isInterestLoan: p => {
      let statusLoan = {0: '否', 1: '是'};
      return statusLoan[p] || p;
    }
  },
  mounted(){
    this.base = base
  }

};
</script>

<style lang="scss" scoped>
.account-table {
  .psd-cell {
    width: 100%;
    position: relative;
    i {
      position: absolute;
      right: 0;
    }
  }
}
</style>
